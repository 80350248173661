import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckbox,
} from '../../../components';

import css from './SignupForm.module.css';
import { generateRandomEmailPrefix } from '../../../util/dataExtractors';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        isGuest,
        isAuthenticated,
        form,
        handleSubmitSignup = () => {},
        onLogout = () => {},
      } = fieldRenderProps;

      const onCreateGuestCheckout = () => {
        if (isAuthenticated) {
          onLogout();
        } else {
          const randomPrefix = generateRandomEmailPrefix('test+3@youandaview.com');
          const guestValues = {
            email: `test+${randomPrefix}@youandaview.com`,
            password: 'Vishal@7717689232',
            fname: 'Guest',
            lname: 'User',
            isGuest: true,
          };
          handleSubmitSignup(guestValues);
        }
      };

      const languageOptions = [
        {
          key: 'EN',
          label: intl.formatMessage({
            id: 'Authentication.englishOption',
          }),
        },
        {
          key: 'DE',
          label: intl.formatMessage({
            id: 'Authentication.germanOption',
          }),
        },
        {
          key: 'FR',
          label: intl.formatMessage({
            id: 'Authentication.frenchOption',
          }),
        },
        {
          key: 'ES',
          label: intl.formatMessage({
            id: 'Authentication.spanishOption',
          }),
        },
        {
          key: 'PT',
          label: intl.formatMessage({
            id: 'Authentication.ptOption',
          }),
        },
        {
          key: 'IT',
          label: intl.formatMessage({
            id: 'Authentication.itOption',
          }),
        },
        {
          key: 'NL',
          label: intl.formatMessage({
            id: 'Authentication.nlOption',
          }),
        },
      ];

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const renderAuthenticationFields = (
        <>
          <FieldTextInput
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            label={intl.formatMessage({
              id: 'SignupForm.emailLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          <div className={css.name}>
            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.firstNameRequired',
                })
              )}
            />
            <FieldTextInput
              className={css.lastNameRoot}
              type="text"
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.lastNameRequired',
                })
              )}
            />
          </div>
          <FieldTextInput
            className={css.password}
            type="password"
            id={formId ? `${formId}.password` : 'password'}
            name="password"
            autoComplete="new-password"
            label={intl.formatMessage({
              id: 'SignupForm.passwordLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.passwordPlaceholder',
            })}
            validate={passwordValidators}
          />
          <FieldSelect
            className={css.password}
            id="preferredLanguage"
            name="preferredLanguage"
            label={intl.formatMessage({
              id: 'Authentication.preferredLanguageLabel',
            })}
            subLabel={intl.formatMessage({
              id: 'Authentication.preferredLanguageLabelHelper',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'Authentication.preferredLanguageRequired',
              })
            )}
          >
            <option disabled value="">
              {intl.formatMessage({ id: 'CustomExtendedDataField.placeholderBoolean' })}
            </option>
            {languageOptions.map(l => (
              <option key={l?.key} value={l?.key}>
                {l?.label}
              </option>
            ))}
          </FieldSelect>

          <div className={classNames(css.newsletter, css.password)}>
            <div className={css.newsletterText}>
              {intl.formatMessage({ id: 'Authentication.newsletterHeading' })}
            </div>
            <FieldCheckbox
              id="newsletter"
              className={css.checkboxGroupField}
              name="newsletter"
              label={intl.formatMessage({ id: 'Authentication.newsletter' })}
              value="true"
            />
          </div>
        </>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {!isGuest ? renderAuthenticationFields : null}

          <div
            className={classNames(css.bottomWrapper, {
              [css.guestBottomWrapper]: isGuest,
            })}
          >
            <TermsAndConditions intl={intl} />
            {isGuest ? (
              <PrimaryButton
                type="button"
                onClick={onCreateGuestCheckout}
                inProgress={submitInProgress}
              >
                <FormattedMessage id="SignupForm.startGuestBooking" />
              </PrimaryButton>
            ) : (
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            )}
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
